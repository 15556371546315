import React, { Component } from 'react';
import CourseListItemComponent from './CourseListItemComponent';

class CourseListComponent extends Component {
    constructor(props) {
      super(props);
    }

    render() {
        return (
           <div className='container'>
                <div className='row'>
                   {this.props.courseList.courses.map((course, index) => 
                      <CourseListItemComponent  key ={course.id} course ={course}/>
                    )}
                </div>
           </div>
        )
    }
}

export default CourseListComponent;
import React, { Component } from 'react';

class FooterComponent extends Component {
    constructor(props) {
      super(props);

    }

    render() {
        return (
            <div>

            </div>
        )
    }
}

export default FooterComponent
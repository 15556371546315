import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';
import {courseListActionCreators} from '../../store/course/CourseList'
import CourseListComponent from './course-sub/CourseListComponent'

class CourseComponent extends Component {
    constructor(props) {
      super(props);
    }

    componentWillMount(){
        this.props.fetchCourseList();
    }

    render() {
        return (
            <div>
               <CourseListComponent courseList = {this.props.courseList}/>
            </div>
        )
    }
}

export default connect(state => state,
    dispatch => bindActionCreators(courseListActionCreators, dispatch)
    )(CourseComponent); 
import React, { Component } from 'react'
import HomeComponent from './components/home/HomeComponent';

class App extends Component {
    constructor(props) {
        super(props)

    }

    render() {
        return (
            <HomeComponent/>
        )
    }
}

export default App
const requestCourseListType = 'Request_Course_List';
const receiveCourseListType = 'Receive_Course_List';
const initialState = {courses: [], isloading : false};

export const courseListActionCreators = {
    fetchCourseList: () => async (dispatch, getState) => {

        dispatch({ type: requestCourseListType });

        const courses = coursesList;
        dispatch({ type: receiveCourseListType, courses });
    }
};

export const reducer = (state, action) => {

    state = state || initialState;

    if (action.type === requestCourseListType) {
        return {
            ...state,
            isloading: true
        };
    }

    if (action.type === receiveCourseListType) {
        return {
            ...state,
            courses: [...state.courses, ...action.courses],
            isloading: false
        };
    }

    return state;
};

const coursesList = [
    {
        "title": "Node.js and Express REST API with Real World Projects ",
        "description": "Learn How To Build Real World, Production Ready REST APIs Using Node. Js, Express, Sequelize, and JavaScript ",
        "imageUrl": "node-rest-api.PNG",
        "udemyUrl": "https://www.udemy.com/course/nodejs-and-express-rest-api-with-real-world-projects/?couponCode=CARLEN1",
        "datePublished": "2019-08-06T00:00:00",
        "id": 10,
        "createDate": "2019-09-10T14:53:16.971693+01:00",
        "modifiedDate": "2019-09-10T14:53:16.9716997+01:00",
        "isDeleted": false
    },
    {
        "title": "ASP.NET CORE and C# REST API With Real World Projects",
        "description": "Learn How To Build Real World, Production Ready REST APIs Using Asp.Net Core and C# ",
        "imageUrl": "dotnetcore-rest-api.jpg",
        "udemyUrl": "https://www.udemy.com/course/aspnet-core-and-c-rest-api-with-real-world-projects/?couponCode=CARLEN2",
        "datePublished": "2019-08-06T00:00:00",
        "id": 8,
        "createDate": "2019-09-10T14:53:16.971693+01:00",
        "modifiedDate": "2019-09-10T14:53:16.9716997+01:00",
        "isDeleted": false
    },
    {
        "title": "Complete React, Redux and GraphQL BootCamp With Real Project ",
        "description": "Learn How To Build Real World Applications Using React, Redux, GraphQL, HTML, CSS, SASS, and JavaScript",
        "imageUrl": "react-bootcamp.jpg",
        "udemyUrl": "https://www.udemy.com/course/complete-react-redux-and-graphql-bootcamp-with-real-projects/?couponCode=CARLEN1",
        "datePublished": "2019-08-06T00:00:00",
        "id": 9,
        "createDate": "2019-09-10T14:53:16.971693+01:00",
        "modifiedDate": "2019-09-10T14:53:16.9716997+01:00",
        "isDeleted": false
    },
    {
        "title": "Fullstack Django and Python Bootcamp With Real Life Projects",
        "description": "Learn How To Build Real World Applications Using Django, Python, HTML, CSS, SASS, Bootstrap, And JavaScript",
        "imageUrl": "fullstack-django-and-python.PNG",
        "udemyUrl": "https://www.udemy.com/course/fullstack-django-and-python-bootcamp-with-real-life-projects/?couponCode=CARLEN4",
        "datePublished": "2019-08-06T00:00:00",
        "id": 3,
        "createDate": "2019-09-10T14:53:16.971693+01:00",
        "modifiedDate": "2019-09-10T14:53:16.9716997+01:00",
        "isDeleted": false
    },
    {
        "title": "Fullstack Asp.Net Core MVC and C# Bootcamp With Real Project",
        "description": "Learn How To Build Real World Applications Using Asp.Net Core MVC, C#, HTML, CSS, SASS, BootStrap, And JavaScript",
        "imageUrl": "fullstack-aspnet-core-mvc.PNG",
        "udemyUrl": "https://www.udemy.com/course/fullstack-aspnet-core-mvc-and-c-bootcamp-with-real-project/?couponCode=CARLEN4",
        "datePublished": "2019-08-06T00:00:00",
        "id": 7,
        "createDate": "2019-09-10T14:53:16.971693+01:00",
        "modifiedDate": "2019-09-10T14:53:16.9716997+01:00",
        "isDeleted": false
    },
    {
        "title": "Fullstack Node.js and Express Bootcamp with Real Projects",
        "description": "Learn How to Build Fullstack Web Applications Using Node, Express, Sequelize, Nunjucks, HTML, CSS, Bootstrap, JavaScript",
        "imageUrl": "fullstack-nodejs-and-express.PNG",
        "udemyUrl": "https://www.udemy.com/course/fullstack-nodejs-and-express-bootcamp-with-real-projects/?couponCode=CARLEN4",
        "datePublished": "2019-09-03T00:00:00",
        "id": 1,
        "createDate": "2019-09-10T14:53:13.2116742+01:00",
        "modifiedDate": "2019-09-10T14:53:13.2166187+01:00",
        "isDeleted": false
    },
    {
        "title": "Fullstack Flask and Python Bootcamp With Real Life Projects",
        "description": "Learn How To Build Real World Applications Using Flask, Python, HTML, CSS, SASS, Bootstrap, And JavaScript",
        "imageUrl": "fullstack-flask-and-python.PNG",
        "udemyUrl": "https://www.udemy.com/course/fullstack-flask-and-python-bootcamp-with-real-life-projects/?couponCode=CARLEN4",
        "datePublished": "2019-06-05T00:00:00",
        "id": 2,
        "createDate": "2019-09-10T14:53:15.6237303+01:00",
        "modifiedDate": "2019-09-10T14:53:15.6237377+01:00",
        "isDeleted": false
    },
    {
        "title": "Web Application Technology Stack",
        "description": "Understanding Web Application Technologies",
        "imageUrl": "web-application-development-technology-stack.PNG",
        "udemyUrl": "https://www.udemy.com/course/web-application-development-technology-stack/?couponCode=CARLEN3",
        "datePublished": "2019-05-09T00:00:00",
        "id": 4,
        "createDate": "2019-09-10T14:53:18.3592234+01:00",
        "modifiedDate": "2019-09-10T14:53:18.3592303+01:00",
        "isDeleted": false
    },
    {
        "title": "Learning How To Be A Software Developer",
        "description": "This course series shows how to become a software developer.",
        "imageUrl": "learning-how-to-be-a-software-developer.PNG",
        "udemyUrl": "https://www.udemy.com/course/learning-how-to-be-a-software-developer/?couponCode=CARLEN3",
        "datePublished": "2019-05-09T00:00:00",
        "id": 5,
        "createDate": "2019-09-10T14:53:19.7894376+01:00",
        "modifiedDate": "2019-09-10T14:53:19.7894454+01:00",
        "isDeleted": false
    },
    {
        "title": "Front End Component Architecture (React and Angular)",
        "description": "Understand how to build a large scale, enterprise grade front end application",
        "imageUrl": "front-end-component-architecture-react-and-angular.PNG",
        "udemyUrl": "https://www.udemy.com/course/front-end-component-architecture-react-and-angular/?couponCode=CARLEN3",
        "datePublished": "2019-05-14T00:00:00",
        "id": 6,
        "createDate": "2019-09-10T14:53:21.3131958+01:00",
        "modifiedDate": "2019-09-10T14:53:21.3132032+01:00",
        "isDeleted": false
    }
]
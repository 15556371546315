import React, { Component } from 'react';

class CourseListItemComponent extends Component {
    constructor(props) {
      super(props);
    }
  
    render() {
        return (
            <div className='col-md-4' style={{paddingBottom : '50px', textAlign:'center'}}>
                <div className="card" style={{}}>
                    <img src={`/img/courseimages/${this.props.course.imageUrl}`} className="card-img-top" alt="..." />
                        <div className="card-body">
                            <div style={{height: '160px'}}>
                                <h5 className="card-title">{this.props.course.title}</h5>
                                <p className="card-text">{this.props.course.description}</p>
                            </div>
                            <a id = "course-link" href={this.props.course.udemyUrl} className="btn btn-secondary btn-lg" target = '_blank'>Get Course</a>
                        </div>
                  </div>
            </div>
        )
    }
}

export default CourseListItemComponent;
import React, { Component } from 'react';
import HeaderComponent from './home-sub/HeaderComponent';
import FooterComponent from './home-sub/FooterComponent';
import BodyComponent from './home-sub/BodyComponent';

class HomeComponent extends Component {
    constructor(props) {
      super(props);
    }

    render() {
        return (
            <div>
                <HeaderComponent/>
                <BodyComponent/>
                <FooterComponent/>
            </div>
        )
    }
}

export default HomeComponent;
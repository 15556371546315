import React, { Component } from 'react';
import CourseComponent from '../../course/CourseComponent';

class BodyComponent extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
           <div style = {{paddingTop: '70px'}}>
                <CourseComponent/>
           </div>
        )
    }
}

export default BodyComponent;